/* eslint-disable camelcase */
import EChatFilter from 'features/Chat/enums/EChatFilter';
import EProfileType from 'enum/EProfileType';
import { v4 as uuidv4 } from 'uuid';

export default {
  getUnreadChats: chats => chats.length
      ? chats.filter(chat => chat.profile_type !== chat.last_message.profile_type && !chat.last_message.read)
      : [],
  getChatsByFilter: ({ chats, filter }) => filter.id === EChatFilter.ACTIVE
      ? chats.filter(chat => chat.status === EChatFilter.ACTIVE)
      : chats.filter(chat => chat.status === EChatFilter.ARCHIVED),
  prepareUserChats: chats => chats
      ? Object.values(chats).sort((a, b) => {
          if (a.last_message.timestamp < b.last_message.timestamp) return 1;
          if (a.last_message.timestamp > b.last_message.timestamp) return -1;
          return 0;
        })
      : [],
  createMessageData: ({ message, bookerId, hosterId }) => {
    const messageData = {
      id: uuidv4(),
      text: message,
      booker_profile_id: bookerId,
      hoster_profile_id: hosterId,
      timestamp: new Date().getTime(),
      read: false,
      notified: false
    };

    return {
      bookerMessage: { ...messageData, profile_type: EProfileType.BOOKER },
      hosterMessage: { ...messageData, profile_type: EProfileType.HOSTER },
    };
  },
  createChatData: ({ bookerRoom }) => {
    const getProfile = profileUser => ({
        avatar: profileUser.user.avatar,
        name: `${profileUser.user.first_name} ${profileUser.user.last_name}`,
        profile_id: profileUser.id,
      });

    const getBooking = booking => ({ id: booking.id, code: booking.booking_code });

    const getRoom = room => ({ id: room.id, name: room.name });

    const chatData = {
      hoster: getProfile(bookerRoom.hoster),
      booker: getProfile(bookerRoom.booker),
      booking: getBooking(bookerRoom.booking),
      room: getRoom(bookerRoom.room),
      timestamp: new Date().getTime(),
      status: EChatFilter.ACTIVE,
      id: uuidv4(),
    };

    return {
      bookerChat: { ...chatData, profile_type: EProfileType.BOOKER },
      hosterChat: { ...chatData, profile_type: EProfileType.HOSTER },
    };
  },
};
