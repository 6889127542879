import cookies from 'next-cookies';

export default {
  checkIfLogged: ctx => {
    const { Authorization } = cookies(ctx);
    if (Authorization === undefined) {
      ctx.res.writeHead(302, { Location: '/' });
      ctx.res.end();
    }
  },
  redirectPermanentTo: (ctx, path) => {
    ctx.res.writeHead(301, { Location: path });
    ctx.res.end();
  },
  redirectTo: (ctx, path) => {
    ctx.res.writeHead(302, { Location: path });
    ctx.res.end();
  },

  setMandatoryRegister: () => {
    sessionStorage.setItem('mandatoryRegister', JSON.stringify(true));
  },
  getMandatoryRegister: () => {
    if (process.browser) return JSON.parse(sessionStorage.getItem('mandatoryRegister')) || false;
    return false;
  },
  removeMandatoryRegister: () => {
    sessionStorage.removeItem('mandatoryRegister');
  },
  returnStatusCode: (res, statusCode) => {
    res.writeHead(statusCode);
    res.end();
  },
  setUserData: data => {
    sessionStorage.setItem('userData', JSON.stringify(data));
  },
  getUserData: () => JSON.parse(sessionStorage.getItem('userData')),
  removeUserData: () => sessionStorage.removeItem('userData'),
  dump: () => encodeURIComponent(JSON.stringify(sessionStorage)),
  restore: async session => {
    const sessionDecrypted = JSON.parse(decodeURIComponent(session));
    Object.keys(sessionDecrypted).map(key => sessionStorage.setItem(key, sessionDecrypted[key]));
  },
};
