export default {
  checkPermissions: async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const granted = devices.some(device => device.label !== '');
      return granted;
    } catch (error) {
      // alert('ERROR', JSON.stringify(error))
      return false;
    }
  },
  askPermissions: async () => navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(mediaStream => {
        mediaStream.getTracks().forEach(track => {
          track.stop();
        });
        return [true, 'Permissão concedida'];
      })
      .catch(err => [false, err]),
};
