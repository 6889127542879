/* eslint-disable no-unused-expressions */
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const event = ({ action, category, label, value }) => {
  if (process.browser) {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label,
      value,
    });
  }
};
const search = {
  searchedAddress: data => {
    const { state, city, neighborhood } = data;
    state && event({ category: 'search', action: 'state', label: state });
    city && event({ category: 'search', action: 'city', label: `${city}/${state}` });
    neighborhood &&
      event({
        category: 'search',
        action: 'neighborhood',
        label: `${neighborhood} - ${city}/${state}`,
      });
  },
  searchedExpertise: expertise => {
    event({ category: 'search', action: 'expertise', label: expertise.description });
  },
  searchedSpecialties: specialties => {
    event({
      category: 'search',
      action: 'specialties',
      label: specialties
        .map(i => i.description)
        .sort()
        .join(', '),
    });
  },
  searchedPrices: (min, max) => {
    event({ category: 'search', action: 'prices', label: `${min || 0} - ${max || 0}` });
  },
  searchedDates: (dateStart, dateEnd) => {
    event({ category: 'search', action: 'dates', label: `${dateStart} - ${dateEnd}` });
  },
  whatsAppClick: place => {
    event({ category: 'whatsapp', action: place });
  },
};

const quickRegister = {
  singUpQuickRegister: type => {
    event({ category: 'sign_up', action: 'QuickRegister', label: type });
  },
  minimumUserQuickRegister: () => {
    event({
      category: 'QuickRegister',
      action: 'Redirect',
      label: 'User already registered with minimum data',
    });
  },
  singUpCompletedQuickRegister: () => {
    event({ category: 'QuickRegister', action: 'Completed' });
  },
  noAccountQuickRegister: () => {
    event({ category: 'QuickRegister', action: 'No Account' });
  },
};

const booking = {
  agreeAndBook: () => {
    event({ category: 'Booking', action: 'agreeAndBook' });
  },
};
const meeting = {
  professionalCreateMeeting: () => {
    event({ category: 'Meeting', action: 'Professional', label: 'create' });
  },
  professionalEndMeeting: () => {
    event({ category: 'Meeting', action: 'Professional', label: 'end' });
  },
  professionalSendEmail: () => {
    event({ category: 'Meeting', action: 'Professional', label: 'email' });
  },
  professionalSendSms: () => {
    event({ category: 'Meeting', action: 'Professional', label: 'sms' });
  },
  patientEnterMeeting: () => {
    event({ category: 'Meeting', action: 'Patient', label: 'View' });
  },
};
const payment = {
  paymentMethod: type => {
    event({ category: 'Payment Method', action: type });
  },
  pixCopy: () => {
    event({ category: 'Copy', action: 'PIX' });
  },
};

const rating = {
  view: () => {
    event({ category: 'Rating', action: 'View' });
  },
  login: () => {
    event({ category: 'Rating', action: 'Login' });
  },
  status: status => {
    event({ category: 'Rating', action: 'Status', label: status });
  },
};

const concierge = {
  box: () => {
    event({ category: 'Concierge', action: 'Open', label: 'Concierge Box Click' });
  },
  banner: () => {
    event({ category: 'Concierge', action: 'Open', label: 'Concierge Banner Click' });
  },
  filter: () => {
    event({ category: 'Concierge', action: 'Open', label: 'Concierge Filter Button Click' });
  },
  sent: () => {
    event({ category: 'Concierge', action: 'Sent', label: 'Concierge Info Sent' });
  },
};

export default {
  ...booking,
  ...search,
  ...quickRegister,
  ...payment,
  concierge: {...concierge},
  meeting: { ...meeting },
  rating: { ...rating },
  signUpEnterPage: () => {
    window.gtag?.('event', 'conversion');
    window.fbq?.('track', 'Lead');
  },
  signUpEnd: () => {
    window.gtag?.('event', 'conversion');
    window.fbq?.('track', 'CompleteRegistration');
  },
};
