import { scroller } from 'react-scroll';

export default {
  to: (id, offset = 0) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset,
    });
  },
};
