import moment from 'moment';
import EBookingSituation from 'enum/EBookingSituation';

export default {
  setRentAddress: (id, address) => {
    sessionStorage.setItem(`booking-address-${id}-dates`, JSON.stringify(address));
    sessionStorage.setItem(`booking-address-${id}-expires`, moment().add(1, 'days'));
  },
  getRentAddress: id => {
    const itemExpired = `booking-address-${id}-expires`;
    const itemDate = `booking-address-${id}-dates`;
    const expireDay = sessionStorage.getItem(itemExpired) || undefined;
    if (moment().isSameOrAfter(expireDay, 'day')) {
      sessionStorage.removeItem(itemExpired);
      sessionStorage.removeItem(itemDate);
      return undefined;
    }
    const obj = JSON.parse(sessionStorage.getItem(itemDate)) || undefined;

    if (Object.keys(obj || {}).length === 0) return undefined;
    return obj;
  },
  removeRentAddress: id => {
    sessionStorage.removeItem(`booking-${id}-dates`);
    sessionStorage.removeItem(`booking-${id}-expires`);
  },
  setCoupon: (coupon) => {
    sessionStorage.setItem(`booking-coupon`, JSON.stringify(coupon));
    sessionStorage.setItem(`booking-coupon-expires`, moment().add(1, 'days'));
  },
  getCoupon: () => {
    const itemExpired = `booking-coupon-expires`;
    const itemDate = `booking-coupon`;
    const expireDay = sessionStorage.getItem(itemExpired) || undefined;
    if (moment().isSameOrAfter(expireDay, 'day')) {
      sessionStorage.removeItem(itemExpired);
      sessionStorage.removeItem(itemDate);
      return undefined;
    }
    const obj = JSON.parse(sessionStorage.getItem(itemDate)) || undefined;

    if (Object.keys(obj || {}).length === 0) return undefined;
    return obj;
  },
  removeCoupon: () => {
    sessionStorage.removeItem(`booking-coupon`);
    sessionStorage.removeItem(`booking-coupon-expires`);
  },
  setPeriods: (id, dates) => {
    sessionStorage.setItem(`booking-${id}-dates`, JSON.stringify(dates));
    sessionStorage.setItem(`booking-${id}-expires`, moment().add(1, 'days'));
  },
  getPeriods: id => {
    const itemExpired = `booking-${id}-expires`;
    const itemDate = `booking-${id}-dates`;
    const expireDay = sessionStorage.getItem(itemExpired) || undefined;
    if (moment().isSameOrAfter(expireDay, 'day')) {
      sessionStorage.removeItem(itemExpired);
      sessionStorage.removeItem(itemDate);
      return undefined;
    }
    const obj = JSON.parse(sessionStorage.getItem(itemDate)) || undefined;

    if (Object.keys(obj || {}).length === 0) return undefined;
    return obj;
  },
  removePeriods: id => {
    sessionStorage.removeItem(`booking-${id}-dates`);
    sessionStorage.removeItem(`booking-${id}-expires`);
  },
  validatePeriodsCancelationPolicy: periods => {
    const today = new Date();

    return periods.some(period => {
      const timeDiff = Math.abs(new Date(period.date).getTime() - today.getTime());

      return Math.ceil(timeDiff / (1000 * 3600)) < 48;
    });
  },
  getBookingStatus: ({ status, approvedSituation, t }) => {
    const bookingApprovedSituation = {
      1: {
        class: 'in-progress',
        text: t('status.inProgress'),
      },
      2: {
        class: 'scheduled',
        text: t('status.scheduled'),
      },
      3: {
        class: 'closed',
        text: t('status.closed'),
      },
    };

    const bookingStatus = {
      [EBookingSituation.NONE]: {
        class: 'pending',
        text: t('status.pending'),
        infoMessage: t('status.pendingPaymentText'),
      },
      [EBookingSituation.PENDING]: {
        class: 'pending',
        text: t('status.pending'),
        infoMessage: t('status.pendingText'),
      },
      [EBookingSituation.APPROVED]: {
        class: approvedSituation ? bookingApprovedSituation[approvedSituation].class : 'approved',
        text: approvedSituation
          ? bookingApprovedSituation[approvedSituation].text
          : t('status.approved'),
        infoMessage: t('status.approvedText'),
      },
      [EBookingSituation.REPROVED]: {
        class: 'reproved',
        text: t('status.reproved'),
        infoMessage: t('status.reprovedText'),
      },
      [EBookingSituation.CANCELED]: {
        class: 'canceled',
        text: t('status.canceled'),
        infoMessage: t('status.canceledText'),
      },
      [EBookingSituation.EXPIRED]: {
        class: 'canceled',
        text: t('status.expired'),
        infoMessage: t('status.expiredText'),
      },
      [EBookingSituation.REPROVED_BY_ADMIN]: {
        class: 'reproved',
        text: t('status.canceled'),
        infoMessage: t('status.canceledText'),
      },
      [EBookingSituation.REPROVED_BY_PAGARME]: {
        class: 'reproved',
        text: t('status.reproved'),
        infoMessage: t('status.reprovedText'),
      },
    };

    return {
      text: bookingStatus[status].text,
      class: bookingStatus[status].class,
      infoMessage: bookingStatus[status].infoMessage,
    };
  },
  removeConflicted(conflicted = [], id) {
    const original = this.getPeriods(id);
    const originalFixed = { ...original };

    conflicted.forEach(dc => {
      Object.keys(original).forEach(key => {
        if (key === dc.date) {
          if (original[key].filter(h => !dc.periods.includes(h)).length > 0)
            originalFixed[key] = original[key].filter(h => !dc.periods.includes(h));
          else delete originalFixed[key];
        }
      });
    });
    this.setPeriods(id, originalFixed);
  },
};
