import loadImage from 'blueimp-load-image';

import logger from './logger';

const fixRotationOfFile = file =>
  new Promise(resolve => {
    loadImage(
      file,
      img => {
        if (img.type === 'error') {
          logger.logError('fixRotationOfFile', img);
          return resolve(false);
        }
        if (img.toBlob) {
          return img.toBlob(blob => {
            resolve(new File([blob], file.name, { lastModified: new Date().getTime() }));
          }, 'image/jpeg');
        }
        return resolve(file);
      }
    );
  });

export default fixRotationOfFile;
