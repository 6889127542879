/* eslint-disable camelcase */
import moment from 'moment';
import gtag from './gtag';

function prepareAddressInfo(address) {
  const types = [
    'country',
    'administrative_area_level_1',
    'administrative_area_level_2',
    'sublocality_level_1',
  ];

  const typesEquivalence = {
    'country': 'country',
    'administrative_area_level_1': 'state',
    'administrative_area_level_2': 'city',
    'sublocality_level_1': 'neighborhood',
  };

  return address.reduce((acc, value) => {
    const type = value.types[0];

    if (types.includes(type)) {
      acc[typesEquivalence[type]] =
        typesEquivalence[type] === 'state' ? value.short_name : value.long_name;
    }

    return acc;
  }, {});
}

export default {
  prepareSearchUrl: async ({
    expertise,
    specialties,
    checkInDate,
    checkOutDate,
    placeObject,
    minPrice,
    maxPrice,
    query,
  }) => {
    const params = new URLSearchParams('');
    let addressInfo;
    let url = `${window.location.pathname}?`;

    /* eslint-disable-next-line camelcase */
    if (placeObject?.address_components) {
      addressInfo = prepareAddressInfo(placeObject.address_components);

      gtag.searchedAddress(addressInfo);

      if (typeof placeObject.geometry.location.lat === 'function') {
        params.set('lat', placeObject.geometry.location.lat());
        params.set('lng', placeObject.geometry.location.lng());
      } else {
        params.set('lat', placeObject.geometry.location.lat);
        params.set('lng', placeObject.geometry.location.lng);
      }
    }

    if (addressInfo?.country) params.set('country', addressInfo.country);
    if (addressInfo?.state) params.set('state', addressInfo.state);
    if (addressInfo?.city) params.set('city', addressInfo.city);
    if (addressInfo?.neighborhood) params.set('neighborhood', addressInfo.neighborhood);

    if (expertise !== undefined) {
      params.set('expertise', expertise.id);
      gtag.searchedExpertise(expertise);
    }
    if (specialties?.length) {
      params.set('specialties', specialties.map(item => `${item.id}`).join(','));
      gtag.searchedSpecialties(specialties);
    }
    if (minPrice) params.set('minPrice', minPrice);
    if (maxPrice) params.set('maxPrice', maxPrice);
    if (minPrice || maxPrice) {
      gtag.searchedPrices(minPrice, maxPrice);
    }

    if (checkInDate) params.set('date_start', moment(checkInDate).format('YYYY-MM-DD'));
    if (checkOutDate) params.set('date_end', moment(checkOutDate).format('YYYY-MM-DD'));
    if (checkInDate || checkOutDate) {
      gtag.searchedDates(
        moment(checkInDate).format('DD-MM-YYYY'),
        moment(checkOutDate).format('DD-MM-YYYY'),
      );
    }

    if (query?.length) {
      url += `&${query.join('&')}`;
    }
    return `${url}&${params.toString()}`;
  },
  isHomePath: () => window.location.pathname === '/',
  isMeetingHotSite: () =>
    window.location.pathname.includes('/video') || window.location.pathname.includes('/room'),
  isMeetingHotSiteClient: () => window.location.pathname.includes('/room/appointment'),
  prepareReturnUrlMeeting: () =>
    window.location.pathname.includes('/video') ? '?returnUrl=/video' : '',
  shouldRedirectToAnotherPage: () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('returnUrl') === null) return '';

    const returnUrl = `${params.get('returnUrl')}`;
    params.delete('returnUrl');

    const url = `${returnUrl}?${params}`.replace('??', '?'); // workaround WTF, this need to be refactor

    return url;
  },
  addToQueryString: (param, value) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(param, value);
    window.history.pushState('', '', `?${urlParams}`);
  },
  removeFromQueryString: param => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(param);
    window.history.replaceState('', '', `?${urlParams}`);
  },
  getFromQueryString: (param, url) => {
    const urlParams = new URLSearchParams(url || window.location.search);
    return urlParams.get(param) || undefined;
  },
};
