import moment from 'moment';

function calculatePrice(periods, price, discounts, coupon = false) {
  const totalPeriods = Object.values(periods).flatMap(item => item).length;
  const totalWithoutDiscount = totalPeriods * price;
  let discount = 0;
  discounts.forEach(item => {
    if (totalPeriods >= Number(item.period)) {
      discount = item.value;
    }
  });

  if(coupon)
    discount = coupon.discount;

  const totalDiscount = (totalWithoutDiscount * discount) / 100;
  const totalWithDiscount = totalWithoutDiscount - totalDiscount;
  return { totalWithDiscount, totalWithoutDiscount, totalDiscount, discount, totalPeriods };
}

function savePixTempTransactionId(id) {
  if (id) sessionStorage.setItem(`pix_transcation_id`, id);
}
function getPixTempTransactionId() {
  return sessionStorage.getItem(`pix_transcation_id`) || undefined;
}

function savePixCode(id, data) {
  sessionStorage.setItem(`pix-${id}`, JSON.stringify(data));
  sessionStorage.setItem(`pix-${id}-expires`, moment().add(10, 'minutes'));
}
function getPixCode(id) {
  return JSON.parse(sessionStorage.getItem(`pix-${id}`)) || undefined;
}
function getPixCodeExpireTime(id) {
  return sessionStorage.getItem(`pix-${id}-expires`) || undefined;
}

function removePixSession(id) {
  sessionStorage.removeItem(`pix-${id}`);
  sessionStorage.removeItem(`pix_transcation_id`);
}

export default {
  calculatePrice,
  savePixCode,
  getPixCode,
  getPixCodeExpireTime,
  savePixTempTransactionId,
  getPixTempTransactionId,
  removePixSession,
};
